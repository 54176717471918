<template>
  <div>
    <b-card
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row class="mb-2">
          <b-col
            cols="12"
            md="12"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-b-toggle.customer-add
                variant="primary"
                @click="customerFormToggle = true"
              >
                <feather-icon
                  icon="UserPlusIcon"
                  class="mr-50"
                />
                <span class="align-middle">Add New Customer</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search By ID Or Name Or Phone Or Email"
              />
<!--              <button-->
<!--                class="btn btn-primary"-->
<!--                @click="searchQuery = searchTerm"-->
<!--              >-->
<!--                Search-->
<!--              </button>-->
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                v-model="deptFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="[
                  {
                    key: 'All Users',
                    value: false
                  },
                  {
                    key: 'With Dept',
                    value: true
                  }
                ]"
                label="key"
                :reduce="dept => dept.value"
                :clearable="true"
                class="w-100 d-inline-block mr-1"
                placeholder="All Users"
              />
              <b-button
                variant="primary"
                @click="handlePrint"
              >
                <!--                <feather-icon-->
                <!--                  icon="PrinterIcon"-->
                <!--                  class="mr-50"-->
                <!--                />-->
                <span class="align-middle">Print</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <h5 class="mt-2">
          Featured Users Filters
        </h5>
        <b-row class="my-2">
          <b-col
            cols="12"
            md="3"
          >
            <div class="w-100 d-flex flex-column align-items-start justify-content-start">
              <input
                v-model="dateFromFilter"
                type="text"
                onfocus="(this.type='date')"
                onblur="(this.type='text')"
                class="form-control w-100"
                placeholder="From Date"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <div class="w-100 d-flex flex-column align-items-start justify-content-start">
              <input
                v-model="dateToFilter"
                type="text"
                onfocus="(this.type='date')"
                onblur="(this.type='text')"
                class="form-control w-100"
                placeholder="To Date"
                :min="dateFromFilter"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <div class="w-100 d-flex flex-column align-items-start justify-content-start">
              <input
                v-model="averageFromFilter"
                type="text"
                class="form-control w-100"
                placeholder="Average From"
                :min="0"
                @keypress="numbersOnly"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <div class="w-100 d-flex flex-column align-items-start justify-content-start">
              <input
                v-model="averageToFilter"
                type="text"
                class="form-control w-100"
                placeholder="Average To"
                :min="averageFromFilter"
                @keypress="numbersOnly"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <div id="customers-table">
        <b-table
          ref="refCustomersTable"
          :items="fetchCustomers"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
          hover
          :current-page="currentPage"
          :per-page="perPage"
          @row-clicked="handleRowClick"
        >

          <template #cell(id)="data">
            {{ data.item.id }}
          </template>

          <template #cell(name)="data">
            {{ data.item.full_name }}
          </template>

          <template #cell(phone)="data">
            <span class="text-nowrap">
              {{ data.item.phone }}
            </span>
          </template>

<!--          <template v-if="!deptFilter" #cell(email)="data">-->
<!--            <span v-if="data.item.email" class="text-nowrap">-->
<!--              {{ data.item.email }}-->
<!--            </span>-->
<!--            <span v-else>&#45;&#45;</span>-->
<!--          </template>-->

          <template v-if="!deptFilter" #cell(averageOrders)="data">
            <span class="text-nowrap">
              {{ data.item.orders_avg_filtered }}
            </span>
          </template>

          <template #cell(lastOrderDate)="data">
            <span class="text-nowrap">
              {{ data.item.last_laundry_order_date }}
            </span>
          </template>

          <!-- <template #cell(ordersCount)="data">
            <span class="text-nowrap">
              {{ data.item.orders_count }}
            </span>
          </template> -->

          <template #cell(subscriptionsBalance)="data">
            <span v-if="data.item.subscriptions_balance">
              {{ data.item.subscriptions_balance }}
            </span>
            <span v-else>--</span>
          </template>

          <template v-if="!deptFilter" #cell(walletAmount)="data">
            <span v-if="data.item.wallet_amount">
              {{ data.item.wallet_amount }}
            </span>
            <span v-else>--</span>
          </template>

        </b-table>
        <div
          v-if="totalDept"
          class="m-2 d-flex justify-content-end align-items-center text-danger font-weight-bolder dept"
        >
          <span>Total Dept: {{ totalDept.toFixed(2) }} KWD</span>
        </div>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="12"
            sm="12"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>
        <b-row>

          <b-col
            cols="12"
            sm="6"
            md="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            md="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCustomers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <customer-details
      v-if="selectedCustomer"
      :customer-id="selectedCustomer"
    />

    <right-sidebar
      v-if="customerFormToggle"
      id="customer-add"
      title="Add Customer"
    >
      <customer-form
        type="add"
      />
    </right-sidebar>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  VBToggle,
  BPagination,
} from 'bootstrap-vue'
import { onMounted, ref, watch } from '@vue/composition-api'
import vSelect from 'vue-select'
import { Printd } from 'printd'
import useCustomers from './useCustomers'
import CustomerDetails from './customerDetails/CustomerDetails.vue'
import RightSidebar from '../sidebar/RightSidebar.vue'
import CustomerForm from './CustomerForm.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    vSelect,
    CustomerDetails,
    RightSidebar,
    CustomerForm,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup(props, context) {
    const customerId = context.root.$route.params.id
    const selectedCustomer = ref(null)
    const customerFormToggle = ref(false)
    const searchTerm = ref('')
    const searchTimer = ref(null)

    onMounted(() => {
      if (customerId) {
        selectedCustomer.value = +customerId
        setTimeout(() => {
          context.root.$emit('bv::show::modal', 'customer-details')
        }, 50)
      }
    })

    const {
      fetchCustomers,
      tableColumns,
      perPage,
      currentPage,
      lastPage,
      totalCustomers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomersTable,
      deptFilter,
      totalDept,
      dateFromFilter,
      dateToFilter,
      averageFromFilter,
      averageToFilter,

      refetchData,
    } = useCustomers()

    watch(searchTerm, () => {
      clearTimeout(searchTimer.value)
      searchTimer.value = setTimeout(() => {
        searchQuery.value = searchTerm.value
      }, 1000)
    })

    const handleRowClick = record => {
      selectedCustomer.value = record.id
      setTimeout(() => {
        context.root.$emit('bv::show::modal', 'customer-details')
      }, 50)
    }

    const numbersOnly = (evt) => {
      evt = evt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }

    const handlePrint = () => {
      const cssText = `@media print {
          .page-break {
              display: block;
              page-break-before: always;
          }
      }

      #customers-table table{
        border-collapse: collapse;
      }

      #customers-table table, #customers-table table tr{
        width: 100% !important;
        text-align: center !important;
      }

      #customers-table table th, #customers-table table td{
        border: 1px solid black !important;
        padding: 5px;
      }

      #customers-table .dept{
        color: red !important;
        text-align: end !important;
        margin-top: 20px;
      }
      `

      const d = new Printd()
      setTimeout(() => {
        d.print(document.getElementById('customers-table'), [cssText])
      }, 50)
    }

    return {
      fetchCustomers,
      tableColumns,
      perPage,
      currentPage,
      lastPage,
      totalCustomers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomersTable,
      deptFilter,
      totalDept,

      refetchData,
      selectedCustomer,
      handleRowClick,
      customerFormToggle,
      searchTerm,
      handlePrint,
      numbersOnly,
      dateFromFilter,
      dateToFilter,
      averageFromFilter,
      averageToFilter,
    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
