<template>
  <div>
    <customers-list />
  </div>
</template>
<script>
import CustomersList from '@/layouts/components/customers/CustomersList.vue'

export default {
  components: {
    CustomersList,
  },
  setup() {
  },
}
</script>
