import { ref, watch, computed } from '@vue/composition-api'
// import store from '@/store'
import axios from 'axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCustomers() {
  // Use toast
  const toast = useToast()

  const refCustomersTable = ref(null)

  // Table Handlers
  // const tableColumns = [
  //   { key: 'id', sortable: false },
  //   { key: 'name', sortable: false },
  //   { key: 'phone', sortable: false },
  //   { key: 'email', sortable: false },
  //   // { key: 'ordersCount', sortable: true },
  //   { key: 'subscriptionsBalance', sortable: false },
  //   { key: 'walletAmount', sortable: false },
  // ]
  const perPage = ref(15)
  const totalCustomers = ref(0)
  const currentPage = ref(1)
  const lastPage = ref(1)
  const perPageOptions = [15, 30, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const deptFilter = ref('')
  const totalDept = ref('')
  const dateFromFilter = ref('')
  const dateToFilter = ref('')
  const averageFromFilter = ref('')
  const averageToFilter = ref('')

  const tableColumns = computed(() => {
    if(!deptFilter.value) {
      return [
        { key: 'id', sortable: false },
        { key: 'name', sortable: false },
        { key: 'phone', sortable: false },
        // { key: 'email', sortable: false },
        { key: 'averageOrders', sortable: false },
        { key: 'lastOrderDate', sortable: false },
        // { key: 'ordersCount', sortable: true },
        { key: 'subscriptionsBalance', sortable: false },
        { key: 'walletAmount', sortable: false },
      ]
    } else {
      return [
        { key: 'id', sortable: false },
        { key: 'name', sortable: false },
        { key: 'phone', sortable: false },
        { key: 'lastOrderDate', sortable: false },
        { key: 'subscriptionsBalance', sortable: false },
      ]
    }
  })

  const dataMeta = computed(() => {
    const localItemsCount = refCustomersTable.value ? refCustomersTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCustomers.value,
    }
  })

  const refetchData = () => {
    refCustomersTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, deptFilter, dateFromFilter, dateToFilter, averageFromFilter, averageToFilter], () => {
    refetchData()
  })

  const fetchCustomers = (ctx, callback) => {
    let url = `${process.env.VUE_APP_BASE_API_URL}/pos/users?paginate=1&page=${currentPage.value}&perPage=${perPage.value}&search=${searchQuery.value}`

    if (deptFilter.value) {
      url += '&dept=true'
    }

    if (dateFromFilter.value) {
      url += `&date_from=${dateFromFilter.value}`
    }

    if (dateToFilter.value) {
      url += `&date_to=${dateToFilter.value}`
    }

    if (averageFromFilter.value) {
      url += `&avg_from=${averageFromFilter.value}`
    }

    if (averageToFilter.value) {
      url += `&avg_to=${averageToFilter.value}`
    }

    axios
      .get(url, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
      .then(res => {
        const customers = res.data.data.items
        totalCustomers.value = res.data.data.total
        currentPage.value = res.data.data.current_page
        lastPage.value = res.data.data.last_page
        totalDept.value = res.data.data.total_dept

        callback(customers)
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching customer's list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchCustomers,
    tableColumns,
    perPage,
    currentPage,
    lastPage,
    totalCustomers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCustomersTable,
    deptFilter,
    totalDept,
    dateFromFilter,
    dateToFilter,
    averageFromFilter,
    averageToFilter,

    refetchData,
  }
}
